import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "./store";
import { ModalPlugin } from "bootstrap-vue";
import JsonCSV from "vue-json-csv";
import VueCryptojs from "vue-cryptojs";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import router from "./router";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(require("vue-moment"));
Vue.use(VueRouter);
Vue.use(store);
Vue.use(ModalPlugin);
Vue.use(require("vue-moment"));
Vue.use(VueCryptojs);
Vue.component("downloadCsv", JsonCSV);

import "./assets/custom.scss";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common["Authorization"] = store.getters.accessToken;
axios.defaults.headers.common["Content-Type"] = "application/json";

function isValidJWT(token) {
  try {
    jwtDecode(token);
    return true;
  } catch {
    return false;
  }
}

axios.interceptors.request.use(async (request) => {
  if (request.url !== "/user/refresh" && request.url !== "/user/login" && request.url !== "/user/two-factor") {
    if (!isValidJWT(store.getters.accessToken)) {
      console.log("Invalid or missing access token");
      this.$router.push({ name: "Login" });
    } else {
      let decodedToken = jwtDecode(store.getters.accessToken);
      let tokenExpiration = moment.unix(decodedToken.exp);
      let isExpired = moment().isAfter(tokenExpiration);

      if (isExpired) {
        let decodedRefreshToken = jwtDecode(store.getters.refreshToken);
        let refreshTokenExpiration = moment.unix(decodedRefreshToken.exp);
        let refreshTokenIsExpired = moment().isAfter(refreshTokenExpiration);

        if (refreshTokenIsExpired) {
          console.log("Refresh token is expired");
          this.$router.push({ name: "Login" });
        } else {
          await axios
            .post(
              "/user/refresh",
              {},
              {
                headers: {
                  Authorization: store.getters.refreshToken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async (tokenRefreshResponse) => {
              store.commit("saveAccessToken", tokenRefreshResponse.data.accessToken);
              store.commit("saveRefreshToken", tokenRefreshResponse.data.refreshToken);
              request.headers.common["Authorization"] = tokenRefreshResponse.data.accessToken;
              axios.defaults.headers.common["Authorization"] = tokenRefreshResponse.data.accessToken;

              await axios.get("/environment").then((response) => {
                store.commit("savePlatforms", response.data.platforms);
                store.commit("savePlatformAccountTimezones", response.data.timezones);
                store.commit("savePlatformAccounts", response.data.accounts);
                store.commit("saveMediaBuyers", response.data.buyers);
                store.commit("saveLanguages", response.data.languages);
                store.commit("saveiabCategories", response.data.iabCategories);
                store.commit("saveBrands", response.data.brands);
                store.commit("saveCountries", response.data.countries);
                store.commit("saveVerticals", response.data.verticals);
                store.commit("savePartners", response.data.partners);
                store.commit("saveTags", response.data.tags);
                store.commit("saveLeadTypes", response.data.leadTypes);
                store.commit("saveActors", response.data.actors);
                store.commit("saveEditors", response.data.editors);
                store.commit("saveScripts", response.data.scripts);
                store.commit("saveTexts", response.data.texts);
                store.commit("saveImages", response.data.images);
                store.commit("saveCreativeAssets", response.data.creativeAssets);
                store.commit("saveTakes", response.data.takes);
                store.commit("saveHooks", response.data.hooks);
                store.commit("saveLocations", response.data.locations);
                store.commit("saveTitles", response.data.titles);
                store.commit("saveDescriptions", response.data.descriptions);
                store.commit("saveCaptions", response.data.captions);
                store.commit("saveMusic", response.data.music);
                store.commit("saveSfx", response.data.sfx);
                store.commit("saveSpecial", response.data.special);
                store.commit("saveVisuals", response.data.visuals);
                store.commit("saveBroll", response.data.broll);
              });
            })
            .catch(async () => {
              console.log("Error refreshing token");
              this.$router.push({ name: "Login" });
            });
        }
      } else {
        request.headers.common["Authorization"] = store.getters.accessToken;
        axios.defaults.headers.common["Authorization"] = store.getters.accessToken;
      }
    }
  }

  if (process.env.NODE_ENV === "development") {
    console.log("Starting Request", JSON.stringify(request, null, 2));
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Response:", JSON.stringify(response, null, 2));
    }
    return response;
  },
  function (error) {
    console.log("Error:", JSON.stringify(error.response, null, 2));
    return error;
  }
);

store.commit("saveStartDate", null);
store.commit("saveEndDate", null);
store.commit("saveStartDate2", null);
store.commit("saveEndDate2", null);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
