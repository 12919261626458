import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import moment from "moment";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Platforms",
    component: () => import("../views/Platforms.vue"),
    props: true,
  },
  {
    path: "/mediabuyer",
    name: "MediaBuyer",
    component: () => import("../views/MediaBuyer.vue"),
    props: true,
  },
  {
    path: "/accounts/:platformUuid?/:platformAccountTimezoneUuid?/:platformAccountUuid?",
    name: "Accounts",
    component: () => import("../views/Accounts.vue"),
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    props: true,
  },
  {
    path: "/twofactor",
    name: "TwoFactor",
    component: () => import("../views/TwoFactor.vue"),
    props: true,
  },
  {
    path: "/assets",
    name: "Assets",
    component: () => import("../views/Assets.vue"),
    props: true,
  },
  {
    path: "/campaigns/day/:platformUuid/:platformAccountTimezoneUuid/:platformAccountUuid/:platformCampaignUuid",
    name: "CampaignDay",
    component: () => import("../views/CampaignDay.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/groups/:platformUuid/:platformAccountTimezoneUuid/:platformAccountUuid/:platformCampaignUuid",
    name: "CampaignGroups",
    component: () => import("../views/CampaignGroups.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/contents/:platformUuid/:platformAccountTimezoneUuid/:platformAccountUuid/:platformCampaignUuid/:platformCampaignGroupUuid?",
    name: "CampaignContents",
    component: () => import("../views/CampaignContents.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/widgets/:platformUuid/:platformAccountTimezoneUuid/:platformAccountUuid/:platformCampaignUuid",
    name: "CampaignWidgets",
    component: () => import("../views/CampaignWidgets.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/subwidgets/:platformUuid/:platformAccountTimezoneUuid/:platformAccountUuid/:platformCampaignUuid",
    name: "CampaignSubWidgets",
    component: () => import("../views/CampaignSubWidgets.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/:platformUuid?/:platformAccountTimezoneUuid?/:platformAccountUuid?",
    name: "Campaigns",
    component: () => import("../views/Campaigns.vue"),
    props: true,
  },
  {
    path: "/widgets/:platformUuid?/:platformAccountTimezoneUuid?",
    name: "WidgetPerformance",
    component: () => import("../views/WidgetPerformance.vue"),
    props: true,
  },
  {
    path: "/subwidgets/:platformUuid?/:platformAccountTimezoneUuid?",
    name: "SubWidgetPerformance",
    component: () => import("../views/SubWidgetPerformance.vue"),
    props: true,
  },
  {
    path: "/contentperformance",
    name: "ContentPerformance",
    component: () => import("../views/ContentPerformance.vue"),
    props: true,
  },
  {
    path: "/tracking",
    name: "ClickChannel",
    component: () => import("../views/ClickChannel.vue"),
    props: true,
  },
  {
    path: "/tracking/performance",
    name: "ClickChannelPerformance",
    component: () => import("../views/ClickChannelPerformance.vue"),
    props: true,
  },
  {
    path: "/tracking/performance/breakdown/:clickChannelUuid",
    name: "ClickChannelPerformanceBreakdown",
    component: () => import("../views/ClickChannelPerformanceBreakdown.vue"),
    props: true,
  },
  {
    path: "/tracking/clickstream",
    name: "ClickStream",
    component: () => import("../views/ClickStream.vue"),
    props: true,
  },
  {
    path: "/tracking/postbackerrors",
    name: "PostbackErrors",
    component: () => import("../views/PostbackErrors.vue"),
    props: true,
  },
  {
    path: "/tracking/:clickChannelUuid?",
    name: "ClickChannelSingle",
    component: () => import("../views/ClickChannelSingle.vue"),
    props: true,
  },
  {
    path: "/creativeAssets",
    name: "creativeAssets",
    component: () => import("../views/CreativeAssets.vue"),
    props: true,
  },
  {
    path: "/creativeAssets/:creativeAssetUuid?",
    name: "creativeAssetSingle",
    component: () => import("../views/CreativeAssetSingle.vue"),
    props: true,
  },
  {
    path: "/campaignbuild",
    name: "CampaignBuild",
    component: () => import("../views/CampaignBuild.vue"),
    props: true,
  },
  {
    path: "/platforms",
    name: "Platform",
    component: () => import("../views/Platform.vue"),
    props: true,
  },
  {
    path: "/platforms/accounts",
    name: "PlatformAccounts",
    component: () => import("../views/PlatformAccounts.vue"),
    props: true,
  },
  {
    path: "/partner/campaigns",
    name: "PartnerCampaigns",
    component: () => import("../views/PartnerCampaign.vue"),
    props: true,
  },
  {
    path: "/partner/performance",
    name: "PartnerPerformance",
    component: () => import("../views/PartnerPerformance.vue"),
    props: true,
  },
  {
    path: "/partner/campaigns/performance",
    name: "PartnerCampaignPerformance",
    component: () => import("../views/PartnerCampaignPerformance.vue"),
    props: true,
  },
  {
    path: "/partner/delivery",
    name: "PartnerDelivery",
    component: () => import("../views/PartnerDelivery.vue"),
    props: true,
  },
  {
    path: "/interstitial",
    name: "Interstitial",
    component: () => import("../views/Interstitial.vue"),
    props: true,
  },
  {
    path: "/interstitial/group",
    name: "InterstitialGroup",
    component: () => import("../views/InterstitialGroup.vue"),
    props: true,
  },
  {
    path: "/interstitial/group/:interstitialGroupUuid",
    name: "InterstitialGroupSingle",
    component: () => import("../views/InterstitialGroupSingle.vue"),
    props: true,
  },
  {
    path: "/partner/campaign/groups",
    name: "PartnerCampaignGroups",
    component: () => import("../views/PartnerCampaignGroup.vue"),
    props: true,
  },
  {
    path: "/partner/campaign/groups/:partnerCampaignGroupUuid",
    name: "PartnerCampaignGroupSingle",
    component: () => import("../views/PartnerCampaignGroupSingle.vue"),
    props: true,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/Tasks.vue"),
    props: true,
  },
  {
    path: "/jobs/logs",
    name: "Logs",
    component: () => import("../views/Logs.vue"),
    props: true,
  },
  {
    path: "/leads/search",
    name: "ReceivedLeads",
    component: () => import("../views/ReceivedLeads.vue"),
    props: true,
  },
  {
    path: "/leads/sold",
    name: "SoldLeads",
    component: () => import("../views/SoldLeads.vue"),
    props: true,
  },
  {
    path: "/leads/withdrawn",
    name: "WithdrawnLeads",
    component: () => import("../views/WithdrawnLeads.vue"),
    props: true,
  },
  {
    path: "/leads/unsold",
    name: "UnsoldLeads",
    component: () => import("../views/UnsoldLeads.vue"),
    props: true,
  },
  {
    path: "/leads/returned",
    name: "ReturnedLeads",
    component: () => import("../views/ReturnedLeads.vue"),
    props: true,
  },
  {
    path: "/leads/rejected",
    name: "RejectedLeads",
    component: () => import("../views/RejectedLeads.vue"),
    props: true,
  },
  {
    path: "/partner/order",
    name: "PartnerOrder",
    component: () => import("../views/PartnerOrder.vue"),
    props: true,
  },
  {
    path: "/partner",
    name: "Partners",
    component: () => import("../views/Partners.vue"),
    props: true,
  },
  {
    path: "/brands",
    name: "Brands",
    component: () => import("../views/Brands.vue"),
    props: true,
  },
  {
    path: "/leads/type",
    name: "LeadType",
    component: () => import("../views/LeadType.vue"),
    props: true,
  },
  {
    path: "/leads/reports/sold",
    name: "LeadSoldReport",
    component: () => import("../views/LeadSoldReport.vue"),
    props: true,
  },
  {
    path: "/partner/reports/disposition",
    name: "PartnerFeedbackReport",
    component: () => import("../views/PartnerFeedbackReport.vue"),
    props: true,
  },
  {
    path: "/content",
    name: "Content",
    component: () => import("../views/Content.vue"),
    props: true,
  },
  {
    path: "/smsreplies",
    name: "SmsReplies",
    component: () => import("../views/SmsReplies.vue"),
    props: true,
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: () => import("../views/Reservations.vue"),
    props: true,
  },
  {
    path: "/pings/:partnerUuid?",
    name: "Pings",
    component: () => import("../views/Pings.vue"),
    props: true,
  },
  {
    path: "/verticals",
    name: "Verticals",
    component: () => import("../views/Verticals.vue"),
  },
  {
    path: "/countries",
    name: "Countries",
    component: () => import("../views/Countries.vue"),
    props: true,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
    props: true,
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    props: true,
  },
  {
    path: "/dnc/:dncUuid?",
    name: "Dnc",
    component: () => import("../views/Dnc.vue"),
    props: true,
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("../views/Alert.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  function isValidJWT(token) {
    try {
      jwtDecode(token);
      return true;
    } catch {
      return false;
    }
  }

  if (to.name !== "Login" && to.name !== "TwoFactor") {
    if (!isValidJWT(store.getters.accessToken)) {
      console.log("Invalid or missing access token");
      next({ name: "Login" });
    } else {
      let decodedToken = jwtDecode(store.getters.accessToken);
      let tokenExpiration = moment.unix(decodedToken.exp);
      let isExpired = moment().isAfter(tokenExpiration);

      if (isExpired) {
        let decodedRefreshToken = jwtDecode(store.getters.refreshToken);
        let refreshTokenExpiration = moment.unix(decodedRefreshToken.exp);
        let refreshTokenIsExpired = moment().isAfter(refreshTokenExpiration);

        if (refreshTokenIsExpired) {
          next({ name: "Login" });
        } else {
          await axios
            .post(
              "/user/refresh",
              {},
              {
                headers: {
                  Authorization: store.getters.refreshToken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async (tokenRefreshResponse) => {
              store.commit("saveAccessToken", tokenRefreshResponse.data.accessToken);
              store.commit("saveRefreshToken", tokenRefreshResponse.data.refreshToken);
              axios.defaults.headers.common["Authorization"] = tokenRefreshResponse.data.accessToken;

              await axios.get("/environment").then((response) => {
                store.commit("savePlatforms", response.data.platforms);
                store.commit("savePlatformAccountTimezones", response.data.timezones);
                store.commit("savePlatformAccounts", response.data.accounts);
                store.commit("saveMediaBuyers", response.data.buyers);
                store.commit("saveLanguages", response.data.languages);
                store.commit("saveiabCategories", response.data.iabCategories);
                store.commit("saveBrands", response.data.brands);
                store.commit("saveCountries", response.data.countries);
                store.commit("saveVerticals", response.data.verticals);
                store.commit("savePartners", response.data.partners);
                store.commit("saveTags", response.data.tags);
                store.commit("saveLeadTypes", response.data.leadTypes);
                store.commit("saveActors", response.data.actors);
                store.commit("saveEditors", response.data.editors);
                store.commit("saveScripts", response.data.scripts);
                store.commit("saveTexts", response.data.texts);
                store.commit("saveImages", response.data.images);
                store.commit("saveCreativeAssets", response.data.creativeAssets);
              });
              next();
            })
            .catch(async () => {
              next({ name: "Login" });
            });
        }
      } else {
        axios.defaults.headers.common["Authorization"] = store.getters.accessToken;
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
